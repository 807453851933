<template>
	<v-container id="crud" fluid tag="section">
		<v-card>
			<v-card-text>
				<v-card outlined>
					<v-card-subtitle>
						<v-btn
							:color="$cv('principal')"
							@click="showPanel = !showPanel"
							text
							:title="
								!showPanel
									? 'Abrir panel de Consulta de Auditoria'
									: 'Cerrar panel de Consulta de Auditoria'
							"
							class="text-left"
						>
							Consulta de Auditoria
						</v-btn>
					</v-card-subtitle>
					<v-expand-transition>
						<v-card-text v-show="showPanel">
							<v-form ref="formBusqueda" v-model="valid" lazy-validation>
								<v-row dense>
									<v-col cols="12" md="4" sm="12">
										<v-text-field
											v-model="formSearch.modulo"
											label="Modulo"
										></v-text-field>
									</v-col>
									<v-col cols="12" md="4" sm="12">
										<v-text-field
											v-model="formSearch.usuario"
											label="Usuario"
										></v-text-field>
									</v-col>
									<v-col cols="12" md="4" sm="12">
										<v-menu
											ref="menuFecha"
											v-model="menuFecha"
											:close-on-content-click="false"
											:return-value.sync="date"
											transition="scale-transition"
											offset-y
											min-width="auto"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													v-model="dateRangeText"
													label="Fecha"
													readonly
													v-bind="attrs"
													v-on="on"
												></v-text-field>
											</template>
											<v-date-picker v-model="dates" range no-title scrollable>
												<v-spacer></v-spacer>
												<v-btn
													text
													:color="$cv('principal')"
													@click="menuFecha = false"
												>
													Cancelar
												</v-btn>
												<v-btn
													text
													:color="$cv('principal')"
													@click="$refs.menuFecha.save(date)"
												>
													OK
												</v-btn>
											</v-date-picker>
										</v-menu>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12" md="12" sm="12" justify="end" align="right">
										<v-btn
											:color="$cv('principal')"
											small
											@click="
												page = 1;
												getAudits();
											"
										>
											Buscar
										</v-btn>
										<v-btn :color="$cv('btnEliminar')" small @click="reset">
											Reset
										</v-btn>
									</v-col>
								</v-row>
								<v-container fill-height fluid>
									<v-row align="center" justify="center">
										<v-col></v-col>
									</v-row>
								</v-container>
							</v-form>
						</v-card-text>
					</v-expand-transition>
				</v-card>
				<v-data-table
					:headers="headers"
					:items="desserts"
					:loading="loading"
					class="elevation-1"
					:options.sync="pagination"
					:server-items-length="total"
					:footer-props="footerProps"
				>
					<template v-slot:top>
						<v-toolbar flat color="white">
							<v-toolbar-title>Consulta de {{ title }}</v-toolbar-title>
							<v-divider class="mx-4" inset vertical></v-divider>
							<v-spacer></v-spacer>

							<v-dialog v-model="dialog" max-width="70%" persistent>
								<v-card>
									<v-form ref="form" v-model="valid" lazy-validation>
										<v-toolbar dark :color="$cv('principal')">
											<v-btn icon dark @click="dialog = false">
												<v-icon>mdi-close</v-icon>
											</v-btn>
											<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
											<v-spacer></v-spacer>
										</v-toolbar>
										<v-card-text>
											<v-container>
												<v-row>
													<v-col cols="12" md="3" sm="12">
														<v-text-field
															v-model="editedItem.created_at"
															label="Fecha"
															readonly
														></v-text-field>
													</v-col>
													<v-col cols="12" md="3" sm="12">
														<v-text-field
															v-model="editedItem.user"
															label="Usuario"
															readonly
														></v-text-field>
													</v-col>
													<v-col cols="12" md="2" sm="12">
														<v-text-field
															v-model="editedItem.event"
															label="Evento"
															readonly
														></v-text-field>
													</v-col>
													<v-col cols="12" md="4" sm="12">
														<v-text-field
															v-model="editedItem.auditable_type"
															label="Modulo"
															readonly
														></v-text-field>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12" md="4" sm="12">
														<v-text-field
															v-model="editedItem.url"
															label="Url"
															readonly
														></v-text-field>
													</v-col>

													<v-col cols="12" md="2" sm="12">
														<v-text-field
															v-model="editedItem.ip_address"
															label="IP"
															readonly
														></v-text-field>
													</v-col>

													<v-col cols="12" md="6" sm="12">
														<v-text-field
															v-model="editedItem.user_agent"
															label="Navegador"
															readonly
														></v-text-field>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12" md="6" sm="12">
														<v-textarea
															auto-grow
															counter
															v-model="editedItem.old_values"
															label="Valor Anterior"
															:rows="
																editedItem.old_values.length > 30 ? 100 : 3
															"
															readonly
														></v-textarea>
													</v-col>

													<v-col cols="12" md="6" sm="12">
														<v-textarea
															auto-grow
															counter
															v-model="editedItem.new_values"
															label="Valor Nuevo"
															:rows="
																editedItem.new_values.length > 30 ? 100 : 3
															"
															readonly
														></v-textarea>
													</v-col>
												</v-row>
											</v-container>
										</v-card-text>
									</v-form>
								</v-card>
							</v-dialog>
						</v-toolbar>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-icon
							small
							class="mr-2"
							:color="$cv('btnEliminar')"
							title="Vista Avanzada"
							@click="editItem(item.id)"
						>
							mdi-eye-arrow-right-outline
						</v-icon>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>

		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			{{ text }}

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
		<vue-confirm-dialog></vue-confirm-dialog>
		<download-excel
			class="btn btn-rounded btn-primary"
			:fields="json_fields"
			:fetch="fetchData"
			:type="dataExportType"
			:name="'report-Auditoria.' + dataExportType"
		>
			Exportar Excel
		</download-excel>
	</v-container>
</template>

<script>
	function title() {
		return "Auditoria";
	}

	export default {
		data: (vm) => ({
			formSearch: [],
			pagination: {},
			total: 0,
			loading: true,
			footerProps: { "items-per-page-options": [5, 10, 15, 30] },
			menuFecha: false,
			showPanel: false,
			dataExportType: "xls",
			json_fields: {
				Usuario: "email",
				Modulo: "auditable_type",
				"Valor Anterior": "old_values",
				"Valor Nuevo": "new_values",
				URL: "url",
				IP: "ip_address",
				Operacion: "event",
				Fecha: "created_at",
			},
			valid: true,
			nowDate: new Date().toISOString().slice(0, 10),
			_method: "PUT",
			autoGrow: true,
			date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
				.toISOString()
				.substr(0, 10),
			rows: 1,
			title: title(),
			baseUrl: "/api/audits",
			urlExport: "/api/auditoria-export",
			menu: false,
			modal: false,
			menu2: false,
			dialog: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			rules: [(v) => v.length <= 1000 || "Max 1000 caracteres"],
			search: "",
			rulesRequired: [(v) => !!v || "Requerido"],
			users: false,
			headers: [
				{ text: "Fecha", filterable: true, sortable: true, value: "created_at" },
				{ text: "Modulo", filterable: true, value: "auditable_type" },
				{ text: "Usuario", filterable: true, value: "email" },
				{ text: "Evento", filterable: true, value: "event" },
				/* { text: "Valor Anterior", filterable: true, value: "old_values" },
		      { text: "Valor Nuevo", filterable: true, value: "new_values" },*/
				{ text: "IP", filterable: true, value: "ip_address" },
				{ text: "Accciones", value: "actions" },
			],

			desserts: [],
			editedIndex: -1,
			editedItem: {
				user_type: "",
				user: "",
				event: "",
				auditable_type: "",
				auditable_id: "",
				old_values: "",
				new_values: "",
				url: "",
				ip_address: "",
				user_agent: "",
				tags: "",
				created_at: "",
			},
			defaultItem: {
				user_type: "",
				user: "",
				event: "",
				auditable_type: "",
				auditable_id: "",
				old_values: "",
				new_values: "",
				url: "",
				ip_address: "",
				user_agent: "",
				tags: "",
				created_at: "",
			},
			dates: [],
		}),

		computed: {
			dateRangeText: {
				get: function () {
					return this.dates.join(" ~ ");
				},
				set: function (date) {
					return date;
				},
			},
			formTitle() {
				return this.editedIndex === -1
					? "Registrar " + this.title
					: "Vista Avanzada de " + this.title;
			},
		},

		watch: {
			dialog(val) {
				val || this.close();
			},
		},

		created() {
			this.getAudits();
		},

		methods: {
			getAudits() {
				this.loading = true;
				if (this.formSearch) {
					let formData = new FormData();
					formData = this.getQryParams(this.formSearch);
					let query = new URLSearchParams(formData).toString();
					axios
						.get(
							this.baseUrl +
								"?" +
								query +
								"&page=" +
								this.pagination.page +
								"&per_page=" +
								this.pagination.itemsPerPage
						)
						.then((r) => {
							this.desserts = r.data.data;
							this.pagination.current = r.data.current_page;
							this.total = r.data.total;
							this.loading = false;
						})
						.catch(function (error) {
							this.snackbar = true;
							this.text = "Error al obtener denuncias. Error: " + error;
							this.color = "error";
						})
						.finally(() => (this.loading = false));

					return;
				}
				if (this.pagination.sortBy && !this.formSearch) {
					const direction = this.pagination.descending ? "desc" : "asc";
					axios
						.get(
							this.baseUrl +
								"?direction=" +
								direction +
								"&sortBy=" +
								this.pagination.sortBy +
								"&page=" +
								this.pagination.page +
								"&per_page=" +
								this.pagination.itemsPerPage
						)
						.then((r) => {
							this.desserts = r.data.data;
							this.pagination.current = r.data.current_page;
							this.total = r.data.total;
							this.loading = false;
						})
						.catch(function (error) {
							this.snackbar = true;
							this.text =
								"Error al obtener planillas del personal. Error: " + error;
							this.color = "error";
						})
						.finally(() => (this.loading = false));
				}

				if (!this.search && !this.pagination.sortBy) {
					//console.log('no hay parametros');
					axios
						.get(
							this.baseUrl +
								"?page=" +
								this.pagination.page +
								"&per_page=" +
								this.pagination.itemsPerPage
						)
						.then((r) => {
							//console.log(r.data);
							this.desserts = r.data.data;
							this.pagination.current = r.data.current_page;
							this.total = r.data.total;
							this.loading = false;
						})
						.catch(function (error) {
							this.snackbar = true;
							this.text =
								"Error al obtener planillas del personal. Error: " + error;
							this.color = "error";
						})
						.finally(() => (this.loading = false));
				}
			},
			reset() {
				this.$refs.formBusqueda.reset();
				this.getAudits();
			},
			async fetchData() {
				let query = this.formSearch;
				let formData = new FormData();
				formData = this.getQryParams(query);
				let queryParams = new URLSearchParams(formData).toString();
				const response = await axios.get(this.urlExport + "?" + queryParams);
				return response.data.data;
			},
			getQryParams(dataSearch) {
				let formData = new FormData();
				if (dataSearch.usuario) {
					formData.append("email", dataSearch.usuario);
				}
				if (dataSearch.modulo) {
					formData.append("auditable_type", dataSearch.modulo);
				}

				if (this.dates.length > 0) {
					console.log(this.dates);

					formData.append("created_at_start", this.dates[0]);
					formData.append("created_at_end", this.dates[1]);
				}

				return formData;
			},
			addFooters(doc) {
				const pageCount = doc.internal.getNumberOfPages();

				doc.setFont("helvetica", "italic");
				doc.setFontSize(8);
				for (var i = 1; i <= pageCount; i++) {
					doc.setPage(i);
					doc.text("Fleet | Fleet", 10, 287, {
						align: "left",
					});
					doc.text("Página " + String(i) + " de " + String(pageCount), 200, 287, {
						align: "right",
					});
				}
			},

			formatDate(date) {
				if (!date) return null;

				const [year, month, day] = date.split("-");
				return `${day}/${month}/${year}`;
			},

			capitalize(s) {
				if (typeof s !== "string") return "";
				return s.charAt(0).toUpperCase() + s.slice(1);
			},

			initialize() {
				axios
					.get(this.baseUrl)
					.then((r) => {
						this.desserts = r.data;
					})
					.catch(function (error) {
						this.snackbar = true;
						this.text = "Error al obtener datos. Error: " + error;
						this.color = "error";
					});
			},

			editItem(id) {
				axios
					.get(this.baseUrl + "/" + id)
					.then((r) => {
						this.editedIndex = r.data.id;
						delete r.data.id;
						this.editedItem = Object.assign({}, r.data);
						this.dialog = true;
					})
					.catch(function (error) {
						this.snackbar = true;
						this.text = "Error al obtener datos. Error: " + error;
						this.color = "error";
					});
			},

			deleteItem(id) {
				this.$confirm({
					message: `Eliminar Registro?`,
					button: {
						no: "Cancelar",
						yes: "Confirmar",
					},

					callback: (confirm) => {
						if (confirm) {
							axios.delete(this.baseUrl + "/" + id).then(
								(response) => {
									this.snackbar = true;
									this.text = "Registro Eliminado";
									this.initialize();
								},
								(error) => {
									this.snackbar = true;
									this.text = "Error al eliminar la planilla Error: " + e.message;
									this.color = "error";
								}
							);
						}
					},
				});
			},

			close() {
				this.dialog = false;
				this.valid = true;
				this.resetValidation();
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				});
			},
			resetValidation() {
				this.$refs.form.resetValidation();
			},

			save() {
				if (this.$refs.form.validate()) {
					if (this.editedIndex > -1) {
						let formData = new FormData();
						let url = this.baseUrl + "/" + this.editedIndex;
						formData.append("_method", "PUT");

						axios
							.post(url, {
								_method: "PUT",
								data: this.editedItem,
							})
							.then((r) => {
								if (r.data.status == 200) {
									this.snackbar = true;
									this.text = "Registro Actualizado";
									this.color = "success";
									this.initialize();
								} else {
									this.snackbar = true;
									this.text =
										"Error al actualizar los datos. Error: " + r.data.data;
									this.color = "error";
								}
							})
							.catch((e) => {
								this.snackbar = true;
								this.text = "Error al actualizar los datos. Error: " + e.message;
								this.color = "error";
							});
					} else {
						let method = "post";
						let url = this.baseUrl;

						axios({
							method,
							url,
							data: this.editedItem,
						})
							.then((r) => {
								this.snackbar = true;
								this.text = "Registro Creado";
								this.color = "success";
								this.initialize();
							})
							.catch((e) => {
								console.log(e);
								this.snackbar = true;
								this.text = "Error al ingresar los datos. Error: " + e.message;
								this.color = "error";
							});
					}

					this.close();
				}
			},
		},
		mounted() {
			console.log("Componente " + this.baseUrl + " creado");
		},
	};
</script>
